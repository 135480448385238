import { Controller } from "stimulus"
import { loadTrimmer } from "../src/video_trimmer"
import { loadHighlighter } from "../src/video_highlighter"
import { loadVideoSourceSelect } from "../src/video_source_select"


export default class extends Controller {

    static targets = ['scriptLoader', 'id', 'saveButton', 'processing']

    connect() {
        this.load(this.idTarget.id)
    }

    load(videoId) {
        loadTrimmer(videoId);
        loadHighlighter(videoId);
        loadVideoSourceSelect(videoId)
    }

    showProcessing() {
        this.processingTarget.style.display = "inline-flex"
        this.saveButtonTarget.style.display = "none"
    }

}