export function loadTrimmer(videoId) {

    console.log("loadTrimmer called", videoId)

    var clipEditor = document.getElementById(`video_${videoId}`)
    console.log(clipEditor)

    var video = clipEditor.getElementsByClassName('video')[0],
    playbackBar = clipEditor.getElementsByClassName('playback')[0],
    playbackRect = null,
    seekableBar = clipEditor.getElementsByClassName('seekable')[0],
    seekableRect = null,
    seekRatio = null,
    progressBar = clipEditor.getElementsByClassName('progress')[0],
    startGrabber = clipEditor.getElementsByClassName('start')[0],
    endGrabber = clipEditor.getElementsByClassName('end')[0],
    trimButton = clipEditor.getElementsByClassName('trim-control')[0],
    startOffset = clipEditor.getElementsByClassName(`video_start_offset`)[0],
    endOffset = clipEditor.getElementsByClassName(`video_end_offset`)[0],
    frontTrimmedRatio = 0,
    endTrimmedRatio = 1;

    var player = {
        video: clipEditor.getElementsByClassName('video')[0],
        start: startOffset.value,
        end: endOffset.value,
        controls: {
            play: clipEditor.getElementsByClassName('play-control')[0],
            seekToStart: clipEditor.getElementsByClassName('seek-start')[0],
            seekToEnd: clipEditor.getElementsByClassName('seek-end')[0],
            reset: clipEditor.getElementsByClassName('reset-control')[0]
        }
    };

    //setting start and end offset values when video trimmer is called to prevent error on saving
    startOffset.value = 0.00

    var playVideo = function () {
        if (player.video.paused) {
            player.video.play();
            player.controls.play.classList.remove('fa-play');
            player.controls.play.classList.add('fa-pause');
            console.log('play video');

            console.log("Reset highlighter")
            let highlighter = clipEditor.getElementsByClassName("drag-circle")[0].getElementsByTagName('img')[0];
            highlighter.style.transform = "translate3d(" + 0 + "px, " + 0 + "px, 0)";

        } else {
            pauseVideo();
        }
        if (player.video.currentTime >= player.end) {
            player.video.currentTime = player.start;
            player.controls.play.classList.remove('fa-play');
            player.controls.play.classList.add('fa-pause');
            console.log('start from beginning');
        }
    };

    var pauseVideo = function () {
        player.video.pause();
        player.controls.play.classList.remove('fa-pause');
        player.controls.play.classList.add('fa-play');
        console.log('pause video');
        video.classList.remove('paused');
    };

    var resetVideo = function () {
        player.start = 0;
        startOffset.value = 0;
        endOffset.value = player.video.duration;
        player.end = player.video.duration;
        player.video.currentTime = 0;
        startGrabber.style.left = '0';
        endGrabber.style.left = '100%';
        seekableBar.style.left = '0';
        seekableBar.style.right = '0';
        endGrabber.style.left = '100%';
        startGrabber.style.left = '0';
        frontTrimmedRatio = 0;
        endTrimmedRatio = 0;
        progressBar.style.right = 0;
        progressBar.style.left = 0;
        console.log('reset video');
    };

    var seekVideo = function (seekRatio) {
        video.currentTime = video.duration * seekRatio;

        var idStartOffset = clipEditor.getElementsByClassName('video_id_start_offset')[0]
        idStartOffset.value = video.currentTime - player.start


        if (video.currentTime > player.start) {
            idStartOffset.value = video.currentTime
        } else {
            idStartOffset.value = 0.01
        }

        updateTimeHovers();
        console.log("idStartOffset.value", idStartOffset.value)
    };


    var updateTimeHovers = function () {
        startGrabber.dataset.start = secondsToTime(player.start)
        endGrabber.dataset.end = secondsToTime(player.end)
    }

    var secondsToTime = function(seconds) {
        var sliceStart = 14
        if (seconds > 3599) {
            sliceStart = 11
        }
        const formattedTime = new Date(seconds * 1000).toISOString().slice(sliceStart, 19);
        return formattedTime
    }

    var updateProgressBar = function () {
        progressBar.style.width = (seekRatio - frontTrimmedRatio) * 100 + '%';
    }

    var updateProgressBarWidth = function () {
        progressBar.style.left = frontTrimmedRatio * 100 + '%';
        progressBar.style.right = (1 - endTrimmedRatio) * 100 + '%';
    }

    var updateSeekableStart = function () {
        player.start = player.video.duration * frontTrimmedRatio;
        seekableBar.style.left = frontTrimmedRatio * 100 + '%';
        startGrabber.style.left = frontTrimmedRatio * 100 + '%';

        startOffset.value = player.start
        endOffset.value = player.end

        updateProgressBar();

    };

    var updateSeekableEnd = function (event) {
        player.end = player.video.duration * endTrimmedRatio;
        seekableBar.style.right = (1 - endTrimmedRatio) * 100 + '%';
        endGrabber.style.left = endTrimmedRatio * 100 + '%';

        endOffset.value = player.end

        updateProgressBar();

    };

    var playheadMove = function (event) {
        var seekAmount = (event.clientX - playbackRect.left) / playbackRect.width;
        seekVideo(seekAmount);
        window.addEventListener('mouseup', playheadDrop);
    }

    var playheadDrop = function () {
        window.removeEventListener('mousemove', playheadMove);
        window.removeEventListener('mouseup', playheadDrop);
    };

    var startGrabberMove = function (event) {
        if (event.type == 'touchmove') {
            var clientX = event.changedTouches[0].clientX
        } else {
            var clientX = event.clientX
        }
        var ratio = (clientX - playbackRect.left) / playbackRect.width;
        if (ratio <= 0) {
            frontTrimmedRatio = 0;
        } else if (ratio >= endTrimmedRatio) {
            frontTrimmedRatio = endTrimmedRatio;
        } else {
            frontTrimmedRatio = ratio;
        }
        updateSeekableStart();
        updateProgressBarWidth();
        seekVideo(frontTrimmedRatio);
    }

    var startGrabberDrop = function () {
        window.removeEventListener('mousemove', startGrabberMove);
        window.removeEventListener('mouseup', startGrabberDrop);

        // mobile
        window.removeEventListener('touchmove', startGrabberMove);
        window.removeEventListener('touchend', startGrabberDrop);
        console.log('start grabber dropped');

        // console.log("currentIDOffset", parseFloat(currentIDOffset))        

        // if (!isNaN(parseFloat(currentIDOffset))) {
        //     var currentIDOffset = clipEditor.getElementsByClassName('video_id_start_offset')[0].value
        //     console.log("currentIDOffset", parseFloat(currentIDOffset))
    
        //     var differenceOfStartOffset = parseFloat(currentIDOffset) - player.start
        //     console.log("differenceOfStartOffset", differenceOfStartOffset)
    
        //     var newIDOffset = parseFloat(currentIDOffset) + differenceOfStartOffset
        //     console.log("newIDOffset", newIDOffset)
            
        //     clipEditor.getElementsByClassName('video_id_start_offset')[0].value = newIDOffset
        // }


    };

    var endGrabberMove = function (event) {
        if (event.type == 'touchmove') {
            var clientX = event.changedTouches[0].clientX
        } else {
            var clientX = event.clientX
        }
        var ratio = (clientX - playbackRect.left) / playbackRect.width;
        if (ratio >= 1) {
            endTrimmedRatio = 1;
        } else if (ratio <= frontTrimmedRatio) {
            endTrimmedRatio = frontTrimmedRatio;
        } else {
            endTrimmedRatio = ratio;
        }
        seekVideo(endTrimmedRatio);
        updateSeekableEnd();
    }

    var endGrabberDrop = function () {
        window.removeEventListener('mousemove', endGrabberMove);
        window.removeEventListener('mouseup', endGrabberDrop);
        window.removeEventListener('touchmove', endGrabberMove);
        window.removeEventListener('touchend', endGrabberDrop);
    };

    seekableBar.addEventListener('mousedown', function (event) {
        pauseVideo();
        playbackRect = playbackBar.getBoundingClientRect();
        var seekAmount = (event.clientX - playbackRect.left) / playbackRect.width;
        seekVideo(seekAmount);
        window.addEventListener('mousemove', playheadMove);
        console.log('seekable bar mousedown');
    });

    seekableBar.addEventListener('click', function (event) {
        pauseVideo();
        playbackRect = playbackBar.getBoundingClientRect();
        var seekAmount = (event.clientX - playbackRect.left) / playbackRect.width;
        seekVideo(seekAmount);
        console.log('seek clicked');
        window.removeEventListener('mousemove', playheadMove);
    });

    // mobile
    seekableBar.addEventListener('touchstart', function (event) {
        pauseVideo();
        playbackRect = playbackBar.getBoundingClientRect();
        var seekAmount = (event.clientX - playbackRect.left) / playbackRect.width;
        seekVideo(seekAmount);
        window.removeEventListener('mousemove', playheadMove);
    });

    // 

    startGrabber.addEventListener('mousedown', function (event) {
        playbackRect = playbackBar.getBoundingClientRect();
        window.addEventListener('mousemove', startGrabberMove);
        window.addEventListener('mouseup', startGrabberDrop);
    });

    endGrabber.addEventListener('mousedown', function (event) {
        playbackRect = playbackBar.getBoundingClientRect();
        window.addEventListener('mousemove', endGrabberMove);
        window.addEventListener('mouseup', endGrabberDrop);  
    });

    // mobile
    startGrabber.addEventListener('touchstart', function (event) {
        playbackRect = playbackBar.getBoundingClientRect();
        window.addEventListener('touchmove', startGrabberMove);
        window.addEventListener('touchend', startGrabberDrop);
    });

    endGrabber.addEventListener('touchstart', function (event) {
        playbackRect = playbackBar.getBoundingClientRect();
        window.addEventListener('touchmove', endGrabberMove);
        window.addEventListener('touchend', endGrabberDrop);  
    });

    //

    trimButton.addEventListener('click', function(event){
        
        fetch(`/videos_loader`, {
            method: 'GET'

        }).then(function(response) {
            console.log(response)
        })

        


        fetch(`/videos/${clipEditor.getElementById("video").value.match(/\d/g).join("")}`, {
            body: formData,
            method: 'PATCH',
            dataType: 'script',
            credentials: "include",
            headers: {
                "X-CSRF-Token": getMetaValue("csrf-token")
            },
        })
        .then(function(response) {
            console.log(response)
            Turbo.visit(response.url);
        })


        let formData = new FormData()

        formData.append("start_offset", player.start);
        formData.append("end_offset", player.end);

        fetch(`/videos/${clipEditor.getElementById("video").value.match(/\d/g).join("")}`, {
            body: formData,
            method: 'PATCH',
            dataType: 'script',
            credentials: "include",
            headers: {
                "X-CSRF-Token": getMetaValue("csrf-token")
            },
        })
        .then(function(response) {
            console.log(response)
            Turbo.visit(response.url);
        })

        function getMetaValue(name) {
            const element = clipEditor.head.querySelector(`meta[name="${name}"]`)
            return element.getAttribute("content")
        }

    });

    video.addEventListener('click', playVideo);

    video.addEventListener('loadeddata', function () {
        player.end = player.video.duration;
        endOffset.value = player.end
    });

    video.addEventListener('timeupdate', function () {
        seekRatio = video.currentTime / video.duration;
        updateProgressBar();
        if (player.video.currentTime >= player.end) {
            pauseVideo();
        }
    })

    player.controls.play.addEventListener('click', playVideo);

    player.controls.seekToStart.addEventListener('click', function () {
        seekVideo(player.start / player.video.duration)
    });

    player.controls.seekToEnd.addEventListener('click', function () {
        seekVideo(player.end / player.video.duration)
    });

    player.controls.reset.addEventListener('click', resetVideo);

    window.addEventListener("keypress", function (event) {
        if (event.charCode === 32) {
            playVideo();
            console.log('space pressed');
        }
    });
};

document.addEventListener("turbo:load", () => {
    loadTrimmer();
});