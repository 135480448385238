import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tab" ]

  connect() {
    this.activate_from_url(document.URL)
  }

  activate_from_url(url) {

    let display_scenario = "default";
    let flash_container = document.getElementById('flash');
    
    if (flash_container.childNodes.length === 0) {
      console.log("Display scenario unchanged: ", display_scenario)
    } else {
      if (flash_container.innerText.includes("Video source was successfully added.")) {
        display_scenario = "highlights_editor";
        console.log("Notices matches highlights", flash_container.innerText, display_scenario)
      } else {
        console.log(flash_container.innerText, display_scenario)
      }
    }

    let anchor = this.anchor_segment(url)
    if (anchor == "highlights_editor") {
      display_scenario = "highlights_editor";
    }

    var panels = Array.from(document.getElementById(this.tabTargets[0].dataset.targetId).parentNode.getElementsByClassName("tabbedPanel"))
    this.hide_all_panels(panels)
    this.deactivate_all_tabs(this.tabTargets)

    if (display_scenario == "default") {
      this.activate_correct_tab("external_video", this.tabTargets, panels)
      this.activate_correct_tab("overview", this.tabTargets, panels)
      this.activate_correct_tab("profile", this.tabTargets, panels)
    } else {
      this.activate_correct_tab("highlights_editor", this.tabTargets, panels)
    }



  }

  activate_correct_tab(id, tabs, panels) {
    tabs.forEach( tab => {
      console.log(tab, tab.hash)
      if (tab.hash.includes(id)) {
        console.log("matched_tab: ", tab)
        tab.classList.remove("inactive")
        tab.classList.add("active")
        console.log("matched_tab: ", tab)
      } else {
        console.log("unmatched_tab: ", tab)
      }
    })

    panels.forEach( panel => {
      if (panel.id.includes(id)) {
        console.log("matched_panel: ", panel)
        panel.classList.add("block")
        panel.classList.remove("hidden")
        console.log("matched_panel: ", panel)
      } else {
        console.log("unmatched_panel: ", panel)
      }
    })
  }

  anchor_segment(url) {
    return url.split('#')[1]
  }

  change() {

    event.preventDefault()

    var tabs = Array.from(event.currentTarget.parentNode.getElementsByTagName("*"))
    this.deactivate_all_tabs(tabs)

    var panels = Array.from(document.getElementById(event.currentTarget.dataset.targetId).parentNode.getElementsByClassName("tabbedPanel"))
    this.hide_all_panels(panels)

    event.currentTarget.classList.remove("inactive")
    event.currentTarget.classList.add("active")

    document.getElementById(event.currentTarget.dataset.targetId).classList.remove("hidden")
    document.getElementById(event.currentTarget.dataset.targetId).classList.add("block")

    var activePanel = document.getElementById(event.currentTarget.dataset.targetId)
    var nestedPanelContainer = activePanel.getElementsByClassName("panelContainer")[0]
    var nestedPanels = Array.from(nestedPanelContainer.getElementsByClassName("tabbedPanel"))

    var nestedTabsContainer = activePanel.getElementsByClassName("tabsContainer")[0]
    var nestedTabs = Array.from(nestedTabsContainer.getElementsByClassName("anchor_tab"))
    console.log(nestedTabs)

    nestedTabs.forEach(target => {
      target.classList.remove("active")
      target.classList.add("inactive")
    })
    nestedTabs[0].classList.remove("inactive")
    nestedTabs[0].classList.add("active")

    nestedPanels[0].classList.remove("hidden")
    nestedPanels[0].classList.add("block")


  }

  deactivate_all_tabs(tabs) {
    tabs.forEach(target => {
      target.classList.remove("active")
      target.classList.add("inactive")
    })
  }

  hide_all_panels(panels) {
    panels.forEach( panel => {
      if (panel.classList.contains("hidden")) {
        console.log('Already hidden')
      } else {
        panel.classList.add("hidden")
      }
    })
  }

}
