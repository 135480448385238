import { Controller } from "stimulus"

export default class extends Controller {
  // Read more about targets in the Stimulus reference guide: https://stimulusjs.org/reference/targets
  static targets = ["videoEditingContainer"]

  // The connect lifecycle method runs each time our controller is connected to the DOM
  // Read more about lifecycle methods in the Stimulus reference guide: https://stimulusjs.org/reference/lifecycle-callbacks
  connect() {
    console.log("We're connected!")
  }

  createSuccess(event) {
    // This might look a little magical but its just deconstructing the standard rails-ujs event detail
    // Read more here: https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
    const [_data, _status, xhr] = event.detail

    console.log("createSuccess is called")
    console.log(event)
    console.log(xhr.response)
    console.log(this.videoEditingContainerTarget.innerHTML)
    console.log(event.detail[0])

    this.videoEditingContainerTarget.innerHTML = event.detail[0].editor
  }

  destroySuccess(event) {
    // This might look a little magical but its just deconstructing the standard rails-ujs event detail
    // Read more here: https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
    const [_data, _status, xhr] = event.detail

    console.log("destroySuccess is called")
    console.log(event)
    console.log(xhr.response)
    console.log(event.detail[0].editor)

    this.videoEditingContainerTarget.innerHTML = event.detail[0].editor
    console.log(event.editor)

    // this.compositeTarget.innerHTML = response.playlist

  }
}