import { Controller } from "stimulus"
export default class extends Controller {
    uploadFile = (e) => {
        var reader = new FileReader();
        reader.onload = function()
        {
            var output = document.getElementById('user_preview_image');
            console.log(`Output: ${e.target.files[0]}......`)
            output.style.backgroundImage = `url(${ reader.result })`
            // output.src = reader.result;
        }
        reader.readAsDataURL(e.target.files[0]);

        // const formData = new FormData();
        // formData.append("user[avatar]", e.target.files[0]);
        //
        // Rails.ajax({
        //     url: "/update_high_school_coach/profile",
        //     type: "PUT",
        //     dataType: 'script',
        //     beforeSend(xhr, options) {
        //         options.data = formData;
        //         return true;
        //     },
        // });
    }
}