import { Controller } from "stimulus"
import { Uppy } from '@uppy/core'
import { Dashboard } from '@uppy/dashboard'
import { ActiveStorageUpload } from '@excid3/uppy-activestorage-upload'
// const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload')

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')


export default class extends Controller {
    static targets = [ "input", "progress", "progressText", "progressWidth", "saveButton", "processing" ]
  
    initialize() {
    }
  
    connect() {

    }
  
    showProgress() {
      this.processingTarget.style.display = "inline-flex"
      this.saveButtonTarget.style.display = "none"
    }
  
    updateProgress() {

        alert("Updating progress")

        console.log(this)
        console.log(this.progressWidthTarget)
    
      const { id, progress } = event.detail
      this.progressWidthTarget.style.width = `${Math.round(progress)}%`
      this.progressTextTarget.innerHTML = `${Math.round(progress)}% complete`
    }
  
    disconnect() {
      this.element.removeEventListener("direct-upload:progress", this.updateProgress)
    }

}