import { Controller } from "stimulus"
import { createSportSelect } from "../src/choices_select"

export default class extends Controller {

    static targets = ['selector']

    connect() {
        "Stimulus sport_set connected"
        this.load()
    }

    load() {
        console.log("Sport set frame is loaded and js is running.")
        createSportSelect(); // Defined in src/choices_select.js
    }
}