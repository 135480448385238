import {Controller} from "stimulus"

export default class extends Controller {
  upload() {
    var reader = new FileReader();
    reader.onload = function () {
    var output = document.getElementById('jsProfileImage');
      output.src = reader.result;
      output.style.display = "block"
    }
    var svg_block = document.getElementById('jsUserSvgImg');
    if(typeof(svg_block) != 'undefined' && svg_block != null) {
      svg_block.style.display = "none"
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  leagueUpload() {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('jsLeagueImage');
      output.src = reader.result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }
  SponsorUpload() {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('jsSponsorImage');
      output.src = reader.result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  updateColor() {
    document.getElementById('colorChange').style.color = document.getElementById('account_brochure_color').value;
  }
}
