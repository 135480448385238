import {Controller} from "stimulus";
import Choices from 'choices.js'

export default class extends Controller {
  static targets = ["container", "headline", "form", "sport", "college"];

  createSportSelect() {
    var sportSelect = document.getElementById('sport')
    // console.log(sportSelect)
    // Passing options (with default options)
    const sport = new Choices(sportSelect, {
      // silent: false,
      // items: [],
      // choices: [],
      renderChoiceLimit: 4,
      // maxItemCount: 1,
      // addItems: true,
      // addItemFilter: null,
      // removeItems: true,
      // removeItemButton: true,
      // editItems: false,
      // duplicateItemsAllowed: true,
      // delimiter: ',',
      // paste: true,
      // searchEnabled: true,
      // searchChoices: true,
      // searchFloor: 1,
      searchResultLimit: 3,
      // searchFields: ['label', 'value'],
      // position: 'auto',
      // resetScrollPosition: true,
      // shouldSort: true,
      // shouldSortItems: false,
      // sorter: () => {},
      // placeholder: true,
      // placeholderValue: "",
      // searchPlaceholderValue: "",
      // prependValue: null,
      // appendValue: null,
      // renderSelectedChoices: 'auto',
      // loadingText: 'Loading...',
      // noResultsText: 'No results found',
      // noChoicesText: 'No choices to choose from',
      // itemSelectText: '+',
      // addItemText: (value) => {
      // return `Press Enter to add <b>"${value}"</b>`;
      // },
      // maxItemText: (maxItemCount) => {
      // return `Only ${maxItemCount} values can be added`;
      // },
      // valueComparer: (value1, value2) => {
      // return value1 === value2;
      // },
      // classNames: {
      //   containerOuter: 'choices',
      //   containerInner: 'choices__inner',
      //   input: 'choices__input',
      //   inputCloned: 'choices__input--cloned',
      //   list: 'choices__list',
      //   listItems: 'choices__list--multiple',
      //   listSingle: 'choices__list--single',
      //   listDropdown: 'choices__list--dropdown',
      //   item: 'choices__item',
      //   itemSelectable: 'choices__item--selectable',
      //   itemDisabled: 'choices__item--disabled',
      //   itemChoice: 'choices__item--choice',
      //   placeholder: 'choices__placeholder',
      //   group: 'choices__group',
      //   groupHeading: 'choices__heading',
      //   button: 'choices__button',
      //   activeState: 'is-active',
      //   // focusState: 'is-focused',
      //   openState: 'is-open',
      //   disabledState: 'is-disabled',
      //   highlightedState: 'is-highlighted',
      //   selectedState: 'is-selected',
      //   flippedState: 'is-flipped',
      //   loadingState: 'is-loading',
      //   noResults: 'has-no-results',
      //   noChoices: 'has-no-choices'
      // },
      // Choices uses the great Fuse library for searching. You
      // can find more options here: https://github.com/krisk/Fusepion
      // fuseOptions: {
      //   include: 'score'
      // },
      // callbackOnInit: null,
      // callbackOnCreateTemplates: null
    });
  }

  createCollegeSelect(collegesObject) {
    // console.log("College select called.")
    // console.log(collegesObject)
    var collegeSelect = document.getElementById('college')
    // console.log(collegeSelect)
    // Passing options (with default options)
    const college = new Choices(collegeSelect, {
      // silent: false,
      // items: [],
      // choices: [],
      renderChoiceLimit: 4,
      // maxItemCount: 1,
      // addItems: true,
      // addItemFilter: null,
      // removeItems: true,
      // removeItemButton: true,
      // editItems: false,
      // duplicateItemsAllowed: true,
      // delimiter: ',',
      // paste: true,
      // searchEnabled: true,
      // searchChoices: true,
      // searchFloor: 1,
      searchResultLimit: 4,
      // searchFields: ['label', 'value'],
      // position: 'auto',
      // resetScrollPosition: true,
      // shouldSort: true,
      // shouldSortItems: false,
      // sorter: () => {},
      // placeholder: true,
      // placeholderValue: "",
      // searchPlaceholderValue: "",
      // prependValue: null,
      // appendValue: null,
      // renderSelectedChoices: 'auto',
      // loadingText: 'Loading...',
      // noResultsText: 'No results found',
      // noChoicesText: 'No choices to choose from',
      // itemSelectText: '+',
      // addItemText: (value) => {
      // return `Press Enter to add <b>"${value}"</b>`;
      // },
      // maxItemText: (maxItemCount) => {
      // return `Only ${maxItemCount} values can be added`;
      // },
      // valueComparer: (value1, value2) => {
      // return value1 === value2;
      // },
      // classNames: {
      //   containerOuter: 'choices',
      //   containerInner: 'choices__inner',
      //   input: 'choices__input',
      //   inputCloned: 'choices__input--cloned',
      //   list: 'choices__list',
      //   listItems: 'choices__list--multiple',
      //   listSingle: 'choices__list--single',
      //   listDropdown: 'choices__list--dropdown',
      //   item: 'choices__item',
      //   itemSelectable: 'choices__item--selectable',
      //   itemDisabled: 'choices__item--disabled',
      //   itemChoice: 'choices__item--choice',
      //   placeholder: 'choices__placeholder',
      //   group: 'choices__group',
      //   groupHeading: 'choices__heading',
      //   button: 'choices__button',
      //   activeState: 'is-active',
      //   // focusState: 'is-focused',
      //   openState: 'is-open',
      //   disabledState: 'is-disabled',
      //   highlightedState: 'is-highlighted',
      //   selectedState: 'is-selected',
      //   flippedState: 'is-flipped',
      //   loadingState: 'is-loading',
      //   noResults: 'has-no-results',
      //   noChoices: 'has-no-choices'
      // },
      // // Choices uses the great Fuse library for searching. You
      // // can find more options here: https://github.com/krisk/Fusepion
      // fuseOptions: {
      //   include: 'score'
      // },
      // callbackOnInit: null,
      // callbackOnCreateTemplates: null
    });
    // let collegeArray = []
    //
    // collegesObject.forEach(college =>
    //   collegeArray.push({ value: college.id, label: college.name })
    // )
    //
    // college.setChoices(
    //   collegeArray
    // );
  }

  createCoachesSelect(coachesObject) {
    var coachesSelect = document.getElementById('email[coaches_select]')
    // Passing options (with default options)
    const coaches = new Choices(coachesSelect, {
      // silent: false,
      // items: [],
      // choices: [],
      renderChoiceLimit: -1,
      // maxItemCount: -1,
      // addItems: true,
      // addItemFilter: null,
      // removeItems: true,
      // removeItemButton: true,
      // editItems: false,
      // duplicateItemsAllowed: true,
      // delimiter: ',',
      // paste: true,
      // searchEnabled: true,
      // searchChoices: true,
      // searchFloor: 1,
      // searchResultLimit: -1,
      // searchFields: ['label', 'value', 'customProperties.college'],
      // position: 'auto',
      // resetScrollPosition: true,
      // shouldSort: true,
      // shouldSortItems: false,
      // sorter: () => {
      // },
      // placeholder: true,
      // placeholderValue: "",
      // searchPlaceholderValue: "",
      // prependValue: null,
      // appendValue: null,
      // renderSelectedChoices: 'auto',
      // loadingText: 'Loading...',
      // noResultsText: 'No results found',
      // noChoicesText: 'No choices to choose from',
      // itemSelectText: '+',
      // addItemText: (value) => {
      //   return `Press Enter to add <b>"${value}"</b>`;
      // },
      // maxItemText: (maxItemCount) => {
      //   return `Only ${maxItemCount} values can be added`;
      // },
      // valueComparer: (value1, value2) => {
      //   return value1 === value2;
      // },
      // classNames: {
      //   containerOuter: 'choices',
      //   containerInner: 'choices__inner',
      //   input: 'choices__input',
      //   inputCloned: 'choices__input--cloned',
      //   list: 'choices__list',
      //   listItems: 'choices__list--multiple',
      //   listSingle: 'choices__list--single',
      //   listDropdown: 'choices__list--dropdown',
      //   item: 'choices__item',
      //   itemSelectable: 'choices__item--selectable',
      //   itemDisabled: 'choices__item--disabled',
      //   itemChoice: 'choices__item--choice',
      //   placeholder: 'choices__placeholder',
      //   group: 'choices__group',
      //   groupHeading: 'choices__heading',
      //   button: 'choices__button',
      //   activeState: 'is-active',
      //   // focusState: 'is-focused',
      //   openState: 'is-open',
      //   disabledState: 'is-disabled',
      //   highlightedState: 'is-highlighted',
      //   selectedState: 'is-selected',
      //   flippedState: 'is-flipped',
      //   loadingState: 'is-loading',
      //   noResults: 'has-no-results',
      //   noChoices: 'has-no-choices'
      // },
      // // Choices uses the great Fuse library for searching. You
      // // can find more options here: https://github.com/krisk/Fusepion
      // fuseOptions: {
      //   include: 'score'
      // },
      // callbackOnInit: null,
      // callbackOnCreateTemplates: null
    });

    // let coachesArray = []
    //
    // coachesObject.forEach(coach =>
    //     coachesArray.push({ value: coach.id, label: `${coach.name}(${coach.title}) - ${coach.email}` })
    // )
    //
    // console.log(coachesArray)
    //
    // coaches.setChoices(
    //     coachesArray
    // );

  }

  connect() {
    let url = `${window.location.pathname}`
    let urlParams = new URLSearchParams(window.location.search)
    console.log(urlParams)

    if (urlParams.has('college_id') && urlParams.has('parent_id')) {
      console.log('params has college')
      url = `${window.location.pathname}?college_id=${urlParams.get('college_id')}&coach_id=${urlParams.get('coach_id')}&admission_email=${urlParams.get('admission_email')}&parent_id=${urlParams.get('parent_id')}`
    } else if (urlParams.has('parent_id')) {
      console.log('params has parent id')
      url = `${window.location.pathname}?parent_id=${urlParams.get('parent_id')}`
    } else if (urlParams.has('college_id')) {
      console.log('params has college id')
      url = `${window.location.pathname}?college_id=${urlParams.get('college_id')}`
    }
    this.load(url)
  }

  load(url) {
    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        // console.log(data)
        // console.log(this.createCollegeSelect)
        // console.log(data.colleges)
        this.formTarget.innerHTML = data.form
        this.createSportSelect()
        this.createCollegeSelect(data.colleges)
        this.createCoachesSelect(data.coaches)
        this.insertProfileLink()
      }
    })
  }

  insertProfileLink() {
    document.getElementById("jsInsertLink").addEventListener("click", function () {
      var site_url = window.origin
      var profile_link = this.getAttribute('data-url')
      var profile_name = this.getAttribute('data-name')
      var aTag = document.createElement('a');
      aTag.setAttribute('href', site_url + profile_link);
      aTag.innerText = "Profile - " + profile_name;
      document.getElementById("email_body").appendChild(aTag)
    })
  }

  setCollege() {
    var sport_id = document.getElementById('college').dataset['sport']
    let url = `${window.location.pathname}?college_id=${this.collegeTarget.value}&sport_id=${sport_id}`
    // Rails.ajax({
    //     type: 'GET',
    //     url: url,
    //     dataType: 'json',
    //     success: (data) => {

    //         this.updateHeadline(data.college)

    //         let coachesArray = []

    //         console.log(this)

    //         data.coaches.forEach(coach =>
    //             coachesArray.push(
    //                 { 
    //                     value: coach.id, 
    //                     label: `${coach.name}(${coach.title}) - ${coach.email}` 
    //                 })
    //         )

    //         console.log(coachesArray)

    //         coaches.setChoices(
    //             coachesArray
    //         );


    //     }
    //   })
    this.load(url)
  }

  updateHeadline(college) {
    this.headlineTarget.innerHTML = `at ${college.name}`
  }

  updateCoaches() {
    console.log(`Update coaches, reload the coaches element to only show coaches from this college: ${this.collegeTarget.value}`)
  }

  setSport() {
    // console.log('Sport setter called')
    // console.log(`This is the value: ${this.sportTarget.value}`)
    // console.log(this.sportTarget.dataset.sportUpdateUrl)
    // let formData = new FormData()
    // formData.append("sport_id", this.sportTarget.value);
    let url = `${window.location.pathname}?sport_id=${this.sportTarget.value}`
    this.load(url)
    // fetch(`${this.sportTarget.dataset.sportUpdateUrl}`, {
    //   body: formData,
    //   method: 'PATCH',
    //   dataType: 'script',
    //   credentials: "include",
    //   headers: {
    //           "X-CSRF-Token": getMetaValue("csrf-token")
    //    },
    // })
    // .then(function(response) {
    //   console.log(response.status)
    //   console.log(response)
    //
    //   this.updateColleges()
    //   this.updateCoaches()
    //   this.updateSport()
    // })
    //
    // function getMetaValue(name) {
    //   const element = document.head.querySelector(`meta[name="${name}"]`)
    //   return element.getAttribute("content")
    // }
  }

  // get params() {
  //   return [
  //     this.sportInDivision,
  //     this.satRange,
  //     this.actRange,
  //     this.majorsSelect,
  //     // this.withSport,
  //     // this.divisionsSelect,
  //     this.searchQuery,
  //     this.rightSize,
  //     this.ownership,
  //     this.isReligious,
  //     this.coaRange,
  //     this.netPriceRange,
  //     this.states,
  //     this.settings
  //   ].join("&");
  // }
  // get ownership() {
  //   return `ownership=${this.ownershipTarget.value}`;
  // }
}