// inline_controller.js
import { Controller } from "stimulus";
import Choices from 'choices.js'

export default class extends Controller {
    connect() {
        if (this.element.getElementsByClassName("select-to-choices").length > 0) {
            this.createSelectField(this.element.getElementsByClassName("select-to-choices")[0])
        }
    }

    update() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            Rails.fire(this.element, 'submit');
        }, 50)
    }

    updateProfile() {
        var reader = new FileReader();
        reader.onload = function () {
            var output = document.getElementById('jsProfileImage');
            output.src = reader.result;
        }
        reader.readAsDataURL(event.target.files[0]);
        Rails.fire(this.element, 'submit');
    }

    handleResults() {
        console.log('updated image');
        location.reload();
    }

    createSelectField(select) {
        console.log("Creating a select")
    
        // Passing options (with default options)
        var choices = new Choices(select, {
            silent: false,
            items: [],
            choices: [],
            renderChoiceLimit: -1,
            maxItemCount: -1,
            addItems: true,
            addItemFilter: null,
            removeItems: true,
            removeItemButton: true,
            editItems: false,
            duplicateItemsAllowed: true,
            delimiter: ',',
            paste: true,
            searchEnabled: true,
            searchChoices: true,
            searchFloor: 1,
            searchResultLimit: -1,
            searchFields: ['label', 'value'],
            position: 'auto',
            resetScrollPosition: true,
            shouldSort: true,
            shouldSortItems: false,
            sorter: () => {},
            placeholder: true,
            placeholderValue: "",
            searchPlaceholderValue: "",
            prependValue: null,
            appendValue: null,
            renderSelectedChoices: 'auto',
            loadingText: 'Loading...',
            noResultsText: 'No results found',
            noChoicesText: 'No choices to choose from',
            itemSelectText: '+',
            addItemText: (value) => {
            return `Press Enter to add <b>"${value}"</b>`;
            },
            maxItemText: (maxItemCount) => {
            return `Only ${maxItemCount} values can be added`;
            },
            valueComparer: (value1, value2) => {
            return value1 === value2;
            },
            classNames: {
                containerOuter: 'choices',
                containerInner: 'choices__inner',
                input: 'choices__input',
                inputCloned: 'choices__input--cloned',
                list: 'choices__list',
                listItems: 'choices__list--multiple',
                listSingle: 'choices__list--single',
                listDropdown: 'choices__list--dropdown',
                item: 'choices__item',
                itemSelectable: 'choices__item--selectable',
                itemDisabled: 'choices__item--disabled',
                itemChoice: 'choices__item--choice',
                placeholder: 'choices__placeholder',
                group: 'choices__group',
                groupHeading: 'choices__heading',
                button: 'choices__button',
                activeState: 'is-active',
                focusState: 'is-focused',
                openState: 'is-open',
                disabledState: 'is-disabled',
                highlightedState: 'is-highlighted',
                selectedState: 'is-selected',
                flippedState: 'is-flipped',
                loadingState: 'is-loading',
                noResults: 'has-no-results',
                noChoices: 'has-no-choices'
            },
            // Choices uses the great Fuse library for searching. You
            // can find more options here: https://github.com/krisk/Fusepion
            fuseOptions: {
            include: 'score'
            },
            callbackOnInit: null,
            callbackOnCreateTemplates: null
        });
    }
}
