import Choices from 'choices.js'

var rigion_hash = {
  "NE": ['ME', 'NH', 'VT', 'MA', 'RI', 'CT'],
  "MA": ['NY', 'PA', 'NJ', 'DE', 'MD'],
  "S": ['WV', 'VA', 'NC', 'SC', 'GA', 'FL', 'AL', 'MS', 'TN', 'KY', 'LA', 'AR'],
  "MW": ['OH', 'IN', 'IL', 'MO', 'KS', 'NE', 'SD', 'ND', 'MN', 'WI', 'IA', 'MI'],
  "SW": ['AZ', 'NM', 'TX', 'OK'],
  "W": ['CO', 'WY', 'MT', 'UT', 'ID', 'NV', 'CA', 'OR', 'WA', 'HI', 'AK']
}

const createMajorsSelect = function () {
  var majorsSelect = document.getElementById('majors_select')

  // Passing options (with default options)
  const majors = new Choices(majorsSelect, {
      silent: false,
      items: [],
      choices: [],
      renderChoiceLimit: -1,
      maxItemCount: -1,
      addItems: true,
      addItemFilter: null,
      removeItems: true,
      removeItemButton: true,
      editItems: false,
      duplicateItemsAllowed: true,
      delimiter: ',',
      paste: true,
      searchEnabled: true,
      searchChoices: true,
      searchFloor: 1,
      searchResultLimit: 7,
      searchFields: ['label', 'value'],
      position: 'auto',
      resetScrollPosition: true,
      shouldSort: true,
      shouldSortItems: false,
      sorter: () => {},
      placeholder: true,
      placeholderValue: "",
      searchPlaceholderValue: "",
      prependValue: null,
      appendValue: null,
      renderSelectedChoices: 'auto',
      loadingText: 'Loading...',
      noResultsText: 'No results found',
      noChoicesText: 'No choices to choose from',
      itemSelectText: '+',
      addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
      },
      maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
      },
      valueComparer: (value1, value2) => {
      return value1 === value2;
      },
      classNames: {
        containerOuter: 'choices',
        containerInner: 'choices__inner',
        input: 'choices__input',
        inputCloned: 'choices__input--cloned',
        list: 'choices__list',
        listItems: 'choices__list--multiple',
        listSingle: 'choices__list--single',
        listDropdown: 'choices__list--dropdown',
        item: 'choices__item',
        itemSelectable: 'choices__item--selectable',
        itemDisabled: 'choices__item--disabled',
        itemChoice: 'choices__item--choice',
        placeholder: 'choices__placeholder',
        group: 'choices__group',
        groupHeading: 'choices__heading',
        button: 'choices__button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-open',
        disabledState: 'is-disabled',
        highlightedState: 'is-highlighted',
        selectedState: 'is-selected',
        flippedState: 'majors-is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noChoices: 'has-no-choices'
      },
      // Choices uses the great Fuse library for searching. You
      // can find more options here: https://github.com/krisk/Fusepion
      fuseOptions: {
      include: 'score'
      },
      callbackOnInit: null,
      callbackOnCreateTemplates: null
  });

  majorsSelect.addEventListener(
    'change',
    function(event) {

      console.log(majors.getValue(true))
      majorsSelect.dataset.majors = majors.getValue(true)

      var selectEvent = new CustomEvent(`majors-change`, {});
      majorsSelect.dispatchEvent(selectEvent);

    },
    false,
  );
  $(".choices__list").css("font-weight", "normal")
}

const createDivisionsSelect = function () {
  var divisionsSelect = document.getElementById('divisions_select')

  // Passing options (with default options)
  const divisions = new Choices(divisionsSelect, {
      silent: false,
      items: [],
      choices: [],
      renderChoiceLimit: 7,
      maxItemCount: 7,
      addItems: true,
      addItemFilter: null,
      removeItems: true,
      removeItemButton: true,
      editItems: false,
      duplicateItemsAllowed: true,
      delimiter: ',',
      paste: true,
      searchEnabled: true,
      searchChoices: true,
      searchFloor: 1,
      searchResultLimit: 7,
      searchFields: ['label', 'value'],
      position: 'auto',
      resetScrollPosition: true,
      shouldSort: true,
      shouldSortItems: false,
      sorter: () => {},
      placeholder: true,
      placeholderValue: "",
      searchPlaceholderValue: "",
      prependValue: null,
      appendValue: null,
      renderSelectedChoices: 'auto',
      loadingText: 'Loading...',
      noResultsText: 'No results found',
      noChoicesText: 'No choices to choose from',
      itemSelectText: '+',
      addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
      },
      maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
      },
      valueComparer: (value1, value2) => {
      return value1 === value2;
      },
      classNames: {
        containerOuter: 'choices',
        containerInner: 'choices__inner',
        input: 'choices__input',
        inputCloned: 'choices__input--cloned',
        list: 'choices__list',
        listItems: 'choices__list--multiple',
        listSingle: 'choices__list--single',
        listDropdown: 'choices__list--dropdown',
        item: 'choices__item',
        itemSelectable: 'choices__item--selectable',
        itemDisabled: 'choices__item--disabled',
        itemChoice: 'choices__item--choice',
        placeholder: 'choices__placeholder',
        group: 'choices__group',
        groupHeading: 'choices__heading',
        button: 'choices__button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-open',
        disabledState: 'is-disabled',
        highlightedState: 'is-highlighted',
        selectedState: 'is-selected',
        flippedState: 'is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noChoices: 'has-no-choices'
      },
      // Choices uses the great Fuse library for searching. You
      // can find more options here: https://github.com/krisk/Fusepion
      fuseOptions: {
      include: 'score'
      },
      callbackOnInit: null,
      callbackOnCreateTemplates: null
  });

  if (divisionsSelect.dataset.enabled == "false") {
    divisions.disable()
  }

  
  console.log(`divisions on creation: ${divisionsSelect.dataset.divisions}`)

  divisionsSelect.addEventListener(
    'change',
    function(event) {
      
      console.log("CHOICES SELECT, ", divisions.getValue(true))
      console.log("CHOICES SELECT, ", divisionsSelect.dataset.divisions)
      divisionsSelect.dataset.divisions = divisions.getValue(true)
      console.log("CHOICES SELECT, ", divisionsSelect.dataset.divisions)

      var selectEvent = new CustomEvent(`divisions-change`, {});
      divisionsSelect.dispatchEvent(selectEvent);

    },
    false,
  );

}

const createConferencesSelect = function () {
  var conferencesSelect = document.getElementById('conferences')

  // Passing options (with default options)
  const conferences = new Choices(conferencesSelect, {
    silent: false,
    items: [],
    choices: [],
    renderChoiceLimit: -1,
    maxItemCount: -1,
    addItems: true,
    addItemFilter: null,
    removeItems: true,
    removeItemButton: true,
    editItems: false,
    duplicateItemsAllowed: true,
    delimiter: ',',
    paste: true,
    searchEnabled: true,
    searchChoices: true,
    searchFloor: 1,
    searchResultLimit: 7,
    searchFields: ['label', 'value'],
    position: 'auto',
    resetScrollPosition: true,
    shouldSort: true,
    shouldSortItems: false,
    sorter: () => {},
    placeholder: true,
    placeholderValue: "",
    searchPlaceholderValue: "",
    prependValue: null,
    appendValue: null,
    renderSelectedChoices: 'auto',
    loadingText: 'Loading...',
    noResultsText: 'No results found',
    noChoicesText: 'No choices to choose from',
    itemSelectText: '+',
    addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
    },
    maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
    },
    valueComparer: (value1, value2) => {
      return value1 === value2;
    },
    classNames: {
      containerOuter: 'choices',
      containerInner: 'choices__inner',
      input: 'choices__input',
      inputCloned: 'choices__input--cloned',
      list: 'choices__list',
      listItems: 'choices__list--multiple',
      listSingle: 'choices__list--single',
      listDropdown: 'choices__list--dropdown',
      item: 'choices__item',
      itemSelectable: 'choices__item--selectable',
      itemDisabled: 'choices__item--disabled',
      itemChoice: 'choices__item--choice',
      placeholder: 'choices__placeholder',
      group: 'choices__group',
      groupHeading: 'choices__heading',
      button: 'choices__button',
      activeState: 'is-active',
      focusState: 'is-focused',
      openState: 'is-open',
      disabledState: 'is-disabled',
      highlightedState: 'is-highlighted',
      selectedState: 'is-selected',
      flippedState: 'is-flipped',
      loadingState: 'is-loading',
      noResults: 'has-no-results',
      noChoices: 'has-no-choices'
    },
    // Choices uses the great Fuse library for searching. You
    // can find more options here: https://github.com/krisk/Fusepion
    fuseOptions: {
      include: 'score'
    },
    callbackOnInit: null,
    callbackOnCreateTemplates: null
  });

  conferencesSelect.addEventListener(
    'change',
    function(event) {
      conferencesSelect.dataset.conferences = conferences.getValue(true)
      var selectEvent = new CustomEvent(`conferences-change`, {});
      conferencesSelect.dispatchEvent(selectEvent);
    },
    false,
  );


}

const createClubSportsSelect = function () {
    var clubSportsSelect = document.getElementById('club_sports')

    console.log(`club sports: ${clubSportsSelect}`)
    // Passing options (with default options)
    const club_sports = new Choices(clubSportsSelect, {
        silent: false,
        items: [],
        choices: [],
        renderChoiceLimit: -1,
        maxItemCount: -1,
        addItems: true,
        addItemFilter: null,
        removeItems: true,
        removeItemButton: true,
        editItems: false,
        duplicateItemsAllowed: true,
        delimiter: ',',
        paste: true,
        searchEnabled: true,
        searchChoices: true,
        searchFloor: 1,
        searchResultLimit: 7,
        searchFields: ['label', 'value'],
        position: 'auto',
        resetScrollPosition: true,
        shouldSort: true,
        shouldSortItems: false,
        sorter: () => {},
        placeholder: true,
        placeholderValue: "",
        searchPlaceholderValue: "",
        prependValue: null,
        appendValue: null,
        renderSelectedChoices: 'auto',
        loadingText: 'Loading...',
        noResultsText: 'No results found',
        noChoicesText: 'No choices to choose from',
        itemSelectText: '+',
        addItemText: (value) => {
            return `Press Enter to add <b>"${value}"</b>`;
        },
        maxItemText: (maxItemCount) => {
            return `Only ${maxItemCount} values can be added`;
        },
        valueComparer: (value1, value2) => {
            return value1 === value2;
        },
        classNames: {
            containerOuter: 'choices',
            containerInner: 'choices__inner',
            input: 'choices__input',
            inputCloned: 'choices__input--cloned',
            list: 'choices__list',
            listItems: 'choices__list--multiple',
            listSingle: 'choices__list--single',
            listDropdown: 'choices__list--dropdown',
            item: 'choices__item',
            itemSelectable: 'choices__item--selectable',
            itemDisabled: 'choices__item--disabled',
            itemChoice: 'choices__item--choice',
            placeholder: 'choices__placeholder',
            group: 'choices__group',
            groupHeading: 'choices__heading',
            button: 'choices__button',
            activeState: 'is-active',
            focusState: 'is-focused',
            openState: 'is-open',
            disabledState: 'is-disabled',
            highlightedState: 'is-highlighted',
            selectedState: 'is-selected',
            flippedState: 'is-flipped',
            loadingState: 'is-loading',
            noResults: 'has-no-results',
            noChoices: 'has-no-choices'
        },
        // Choices uses the great Fuse library for searching. You
        // can find more options here: https://github.com/krisk/Fusepion
        fuseOptions: {
            include: 'score'
        },
        callbackOnInit: null,
        callbackOnCreateTemplates: null
    });

    clubSportsSelect.addEventListener(
        'change',
        function(event) {
            clubSportsSelect.dataset.club_sports = club_sports.getValue(true)
            var selectEvent = new CustomEvent(`club_sports-change`, {});
            clubSportsSelect.dispatchEvent(selectEvent);
        },
        false,
    );


}

const createReligionsSelect = function () {
  var religionsSelect = document.getElementById('religions')

  // Passing options (with default options)
  const religions = new Choices(religionsSelect, {
      silent: false,
      items: [],
      choices: [],
      renderChoiceLimit: -1,
      maxItemCount: -1,
      addItems: true,
      addItemFilter: null,
      removeItems: true,
      removeItemButton: true,
      editItems: false,
      duplicateItemsAllowed: true,
      delimiter: ',',
      paste: true,
      searchEnabled: true,
      searchChoices: true,
      searchFloor: 1,
      searchResultLimit: 7,
      searchFields: ['label', 'value'],
      position: 'auto',
      resetScrollPosition: true,
      shouldSort: true,
      shouldSortItems: false,
      sorter: () => {},
      placeholder: true,
      placeholderValue: "",
      searchPlaceholderValue: "",
      prependValue: null,
      appendValue: null,
      renderSelectedChoices: 'auto',
      loadingText: 'Loading...',
      noResultsText: 'No results found',
      noChoicesText: 'No choices to choose from',
      itemSelectText: '+',
      addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
      },
      maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
      },
      valueComparer: (value1, value2) => {
      return value1 === value2;
      },
      classNames: {
        containerOuter: 'choices',
        containerInner: 'choices__inner',
        input: 'choices__input',
        inputCloned: 'choices__input--cloned',
        list: 'choices__list',
        listItems: 'choices__list--multiple',
        listSingle: 'choices__list--single',
        listDropdown: 'choices__list--dropdown',
        item: 'choices__item',
        itemSelectable: 'choices__item--selectable',
        itemDisabled: 'choices__item--disabled',
        itemChoice: 'choices__item--choice',
        placeholder: 'choices__placeholder',
        group: 'choices__group',
        groupHeading: 'choices__heading',
        button: 'choices__button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-open',
        disabledState: 'is-disabled',
        highlightedState: 'is-highlighted',
        selectedState: 'is-selected',
        flippedState: 'is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noChoices: 'has-no-choices'
      },
      // Choices uses the great Fuse library for searching. You
      // can find more options here: https://github.com/krisk/Fusepion
      fuseOptions: {
      include: 'score'
      },
      callbackOnInit: null,
      callbackOnCreateTemplates: null
  });

  var religions_hash = {
      "protestant": "Protestant",
      "catholic": "Catholic",
      "jewish": "Jewish",
      "other": "Other"

    // "51": "African Methodist Episcopal",
    // "24": "African Methodist Episcopal Zion Church",
    // "52": "American Baptist",
    // "22": "American Evangelical Lutheran Church",
    // "53": "American Lutheran",
    // "27": "Assemblies of God Church",
    // "54": "Baptist",
    // "28": "Brethren Church",
    // "34": "Christ and Missionary Alliance Church",
    // "61": "Christian Church (Disciples of Christ)",
    // "48": "Christian Churches and Churches of Christ",
    // "55": "Christian Methodist Episcopal",
    // "35": "Christian Reformed Church",
    // "58": "Church of Brethren",
    // "57": "Church of God",
    // "59": "Church of the Nazarene",
    // "74": "Churches of Christ",
    // "60": "Cumberland Presbyterian",
    // "101": "Ecumenical Christian",
    // "50": "Episcopal Church, Reformed",
    // "102": "Evangelical Christian",
    // "36": "Evangelical Congregational Church",
    // "37": "Evangelical Covenant Church of America",
    // "38": "Evangelical Free Church of America",
    // "39": "Evangelical Lutheran Church",
    // "64": "Free Methodist",
    // "41": "Free Will Baptist Church",
    // "65": "Friends",
    // "91": "Greek Orthodox",
    // "42": "Interdenominational",
    // "40": "International United Pentecostal Church",
    // "80": "Jewish",
    // "94": "Latter Day Saints (Mormon Church)",
    // "68": "Lutheran Church - Missouri Synod",
    // "67": "Lutheran Church in America",
    // "43": "Mennonite Brethren Church",
    // "69": "Mennonite Church",
    // "87": "Missionary Church Inc",
    // "44": "Moravian Church",
    // "78": "Multiple Protestant Denomination",
    // "45": "North American Baptist",
    // "100": "Original Free Will Baptist",
    // "79": "Other Protestant",
    // "47": "Pentecostal Holiness Church",
    // "103": "Presbyterian",
    // "66": "Presbyterian Church (USA)",
    // "73": "Protestant Episcopal",
    // "77": "Protestant, not specified",
    // "49": "Reformed Church in America",
    // "81": "Reformed Presbyterian Church",
    // "30": "Roman Catholic",
    // "92": "Russian Orthodox",
    // "95": "Seventh Day Adventists",
    // "75": "Southern Baptist",
    // "97": "The Presbyterian Church in America",
    // "88": "Undenominational",
    // "93": "Unitarian Universalist",
    // "84": "United Brethren Church",
    // "76": "United Church of Christ",
    // "71": "United Methodist",
    // "89": "Wesleyan",
    // "33": "Wisconsin Evangelical Lutheran Synod",
    // "99": "Other (none of the above)"
  }

  var religion_choices = []

  for (const [key, value] of Object.entries(religions_hash)) {
    religion_choices.push({ value: key, label: value })
  }

  religions.setChoices(
    religion_choices
  );
}

var states
var statesSelect
const createStatesSelect = function () {
  statesSelect = document.getElementById('states')

  // Passing options (with default options)
  states = new Choices(statesSelect, {
      silent: false,
      items: [],
      choices: [],
      renderChoiceLimit: -1,
      maxItemCount: -1,
      addItems: true,
      addItemFilter: null,
      removeItems: true,
      removeItemButton: true,
      editItems: false,
      duplicateItemsAllowed: true,
      delimiter: ',',
      paste: true,
      searchEnabled: true,
      searchChoices: true,
      searchFloor: 1,
      searchResultLimit: 7,
      searchFields: ['label', 'value'],
      position: 'auto',
      resetScrollPosition: true,
      shouldSort: true,
      shouldSortItems: false,
      sorter: () => {},
      placeholder: true,
      placeholderValue: "",
      searchPlaceholderValue: "",
      prependValue: null,
      appendValue: null,
      renderSelectedChoices: 'auto',
      loadingText: 'Loading...',
      noResultsText: 'No results found',
      noChoicesText: 'No choices to choose from',
      itemSelectText: '+',
      addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
      },
      maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
      },
      valueComparer: (value1, value2) => {
      return value1 === value2;
      },
      classNames: {
        containerOuter: 'choices',
        containerInner: 'choices__inner',
        input: 'choices__input',
        inputCloned: 'choices__input--cloned',
        list: 'choices__list',
        listItems: 'choices__list--multiple',
        listSingle: 'choices__list--single',
        listDropdown: 'choices__list--dropdown',
        item: 'choices__item',
        itemSelectable: 'choices__item--selectable',
        itemDisabled: 'choices__item--disabled',
        itemChoice: 'choices__item--choice',
        placeholder: 'choices__placeholder',
        group: 'choices__group',
        groupHeading: 'choices__heading',
        button: 'choices__button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-open',
        disabledState: 'is-disabled',
        highlightedState: 'is-highlighted',
        selectedState: 'is-selected',
        flippedState: 'is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noChoices: 'has-no-choices'
      },
      // Choices uses the great Fuse library for searching. You
      // can find more options here: https://github.com/krisk/Fusepion
      fuseOptions: {
      include: 'score'
      },
      callbackOnInit: null,
      callbackOnCreateTemplates: null
  });

  statesSelect.addEventListener(
    'change',
    function(event) {
      statesSelect.dataset.states = states.getValue(true)
      var selectEvent = new CustomEvent(`states-change`, {});
      statesSelect.dispatchEvent(selectEvent);
    },
    false,
  );

  // var states_hash = {
  //   "AK": "Alaska",
  //   "AL": "Alabama",
  //   "AR": "Arkansas",
  //   "AS": "American Samoa",
  //   "AZ": "Arizona",
  //   "CA": "California",
  //   "CO": "Colorado",
  //   "CT": "Connecticut",
  //   "DC": "District of Columbia",
  //   "DE": "Delaware",
  //   "FL": "Florida",
  //   "FM": "Federated States of Micronesia",
  //   "GA": "Georgia",
  //   "GU": "Guam",
  //   "HI": "Hawaii",
  //   "IA": "Iowa",
  //   "ID": "Idaho",
  //   "IL": "Illinois",
  //   "IN": "Indiana",
  //   "KS": "Kansas",
  //   "KY": "Kentucky",
  //   "LA": "Louisiana",
  //   "MA": "Massachusetts",
  //   "MD": "Maryland",
  //   "ME": "Maine",
  //   "MH": "Marshall Islands",
  //   "MI": "Michigan",
  //   "MN": "Minnesota",
  //   "MO": "Missouri",
  //   "MP": "Northern Mariana Islands",
  //   "MS": "Mississippi",
  //   "MT": "Montana",
  //   "NC": "North Carolina",
  //   "ND": "North Dakota",
  //   "NE": "Nebraska",
  //   "NH": "New Hampshire",
  //   "NJ": "New Jersey",
  //   "NM": "New Mexico",
  //   "NV": "Nevada",
  //   "NY": "New York",
  //   "OH": "Ohio",
  //   "OK": "Oklahoma",
  //   "OR": "Oregon",
  //   "PA": "Pennsylvania",
  //   "PR": "Puerto Rico",
  //   "PW": "Palau",
  //   "RI": "Rhode Island",
  //   "SC": "South Carolina",
  //   "SD": "South Dakota",
  //   "TN": "Tennessee",
  //   "TX": "Texas",
  //   "UT": "Utah",
  //   "VA": "Virginia",
  //   "VI": "Virgin Islands",
  //   "VT": "Vermont",
  //   "WA": "Washington",
  //   "WI": "Wisconsin",
  //   "WV": "West Virginia",
  //   "WY": "Wyoming"
  // }

  // var states_choices = []

  // for (const [key, value] of Object.entries(states_hash)) {
  //   states_choices.push({ value: key, label: value })
  // }

  // states.setChoices(
  //   states_choices
  // );

}

const createSettingsSelect = function () {
  var settingsSelect = document.getElementById('settings')

  // Passing options (with default options)
  const settings = new Choices(settingsSelect, {
      silent: false,
      items: [],
      choices: [],
      renderChoiceLimit: -1,
      maxItemCount: -1,
      addItems: true,
      addItemFilter: null,
      removeItems: true,
      removeItemButton: true,
      editItems: false,
      duplicateItemsAllowed: true,
      delimiter: ',',
      paste: true,
      searchEnabled: true,
      searchChoices: true,
      searchFloor: 1,
      searchResultLimit: 7,
      searchFields: ['label', 'value'],
      position: 'auto',
      resetScrollPosition: true,
      shouldSort: true,
      shouldSortItems: false,
      sorter: () => {},
      placeholder: true,
      placeholderValue: "",
      searchPlaceholderValue: "",
      prependValue: null,
      appendValue: null,
      renderSelectedChoices: 'auto',
      loadingText: 'Loading...',
      noResultsText: 'No results found',
      noChoicesText: 'No choices to choose from',
      itemSelectText: '+',
      addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
      },
      maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
      },
      valueComparer: (value1, value2) => {
      return value1 === value2;
      },
      classNames: {
        containerOuter: 'choices',
        containerInner: 'choices__inner',
        input: 'choices__input',
        inputCloned: 'choices__input--cloned',
        list: 'choices__list',
        listItems: 'choices__list--multiple',
        listSingle: 'choices__list--single',
        listDropdown: 'choices__list--dropdown',
        item: 'choices__item',
        itemSelectable: 'choices__item--selectable',
        itemDisabled: 'choices__item--disabled',
        itemChoice: 'choices__item--choice',
        placeholder: 'choices__placeholder',
        group: 'choices__group',
        groupHeading: 'choices__heading',
        button: 'choices__button',
        activeState: 'is-active',
        focusState: 'is-focused',
        openState: 'is-open',
        disabledState: 'is-disabled',
        highlightedState: 'is-highlighted',
        selectedState: 'is-selected',
        flippedState: 'is-flipped',
        loadingState: 'is-loading',
        noResults: 'has-no-results',
        noChoices: 'has-no-choices'
      },
      // Choices uses the great Fuse library for searching. You
      // can find more options here: https://github.com/krisk/Fusepion
      fuseOptions: {
      include: 'score'
      },
      callbackOnInit: null,
      callbackOnCreateTemplates: null
  });

  settingsSelect.addEventListener(
    'change',
    function(event) {

      console.log(settings.getValue(true))
      console.log(settingsSelect.dataset.settings)
      settingsSelect.dataset.settings = settings.getValue(true)
      console.log(settingsSelect.dataset.settings)

      var selectEvent = new CustomEvent(`settings-change`, {});
      settingsSelect.dispatchEvent(selectEvent);

    },
    false,
  );

  // var settings_hash = {
  //   // '11': 'City: Large',
  //   // '12': 'City: Midsize',
  //   // '13': 'City: Small',
  //   // '21': 'Suburb: Large',
  //   // '22': 'Suburb: Midsize',
  //   // '23': 'Suburb: Small',
  //   // '31': 'Town: Fringe',
  //   // '32': 'Town: Distant',
  //   // '33': 'Town: Remote',
  //   // '41': 'Rural: Fringe',
  //   // '42': 'Rural: Distant',
  //   // '43': 'Rural: Remote'
  //   'City': ['11','12','13'],
  //   'Suburban': ['21','22','23'],
  //   'Rural': ['31','32','33','41','42','43']
  // }

  // var setting_choices = []

  // for (const [key, value] of Object.entries(settings_hash)) {
  //   setting_choices.push({ value: value, label: key })
  // }

  // settings.setChoices(
  //   setting_choices
  // );
}


var ownership;
const createOwnershipSelect = function () {
    var ownershipSelect = document.getElementById('ownership')
  
    // Passing options (with default options)
    ownership = new Choices(ownershipSelect, {
        silent: false,
        items: [],
        choices: [],
        renderChoiceLimit: -1,
        maxItemCount: -1,
        addItems: true,
        addItemFilter: null,
        removeItems: true,
        removeItemButton: true,
        editItems: false,
        duplicateItemsAllowed: true,
        delimiter: ',',
        paste: true,
        searchEnabled: true,
        searchChoices: true,
        searchFloor: 1,
        searchResultLimit: 7,
        searchFields: ['label', 'value'],
        position: 'auto',
        resetScrollPosition: true,
        shouldSort: true,
        shouldSortItems: false,
        sorter: () => {},
        placeholder: true,
        placeholderValue: "",
        searchPlaceholderValue: "",
        prependValue: null,
        appendValue: null,
        renderSelectedChoices: 'auto',
        loadingText: 'Loading...',
        noResultsText: 'No results found',
        noChoicesText: 'No choices to choose from',
        itemSelectText: '+',
        addItemText: (value) => {
        return `Press Enter to add <b>"${value}"</b>`;
        },
        maxItemText: (maxItemCount) => {
        return `Only ${maxItemCount} values can be added`;
        },
        valueComparer: (value1, value2) => {
        return value1 === value2;
        },
        classNames: {
          containerOuter: 'choices',
          containerInner: 'choices__inner',
          input: 'choices__input',
          inputCloned: 'choices__input--cloned',
          list: 'choices__list',
          listItems: 'choices__list--multiple',
          listSingle: 'choices__list--single',
          listDropdown: 'choices__list--dropdown',
          item: 'choices__item',
          itemSelectable: 'choices__item--selectable',
          itemDisabled: 'choices__item--disabled',
          itemChoice: 'choices__item--choice',
          placeholder: 'choices__placeholder',
          group: 'choices__group',
          groupHeading: 'choices__heading',
          button: 'choices__button',
          activeState: 'is-active',
          focusState: 'is-focused',
          openState: 'is-open',
          disabledState: 'is-disabled',
          highlightedState: 'is-highlighted',
          selectedState: 'is-selected',
          flippedState: 'is-flipped',
          loadingState: 'is-loading',
          noResults: 'has-no-results',
          noChoices: 'has-no-choices'
        },
        // Choices uses the great Fuse library for searching. You
        // can find more options here: https://github.com/krisk/Fusepion
        fuseOptions: {
        include: 'score'
        },
        callbackOnInit: null,
        callbackOnCreateTemplates: null
    });    
}

const createRegionsSelect = function () {
  var regionsSelect = document.getElementById('regions')

  // Passing options (with default options)
  const regions = new Choices(regionsSelect, {
    silent: false,
    items: [],
    choices: [],
    renderChoiceLimit: -1,
    maxItemCount: -1,
    addItems: true,
    addItemFilter: null,
    removeItems: true,
    removeItemButton: true,
    editItems: false,
    duplicateItemsAllowed: true,
    delimiter: ',',
    paste: true,
    searchEnabled: true,
    searchChoices: true,
    searchFloor: 1,
    searchResultLimit: 7,
    searchFields: ['label', 'value'],
    position: 'auto',
    resetScrollPosition: true,
    shouldSort: true,
    shouldSortItems: false,
    sorter: () => {},
    placeholder: true,
    placeholderValue: "",
    searchPlaceholderValue: "",
    prependValue: null,
    appendValue: null,
    renderSelectedChoices: 'auto',
    loadingText: 'Loading...',
    noResultsText: 'No results found',
    noChoicesText: 'No choices to choose from',
    itemSelectText: '+',
    addItemText: (value) => {
      return `Press Enter to add <b>"${value}"</b>`;
    },
    maxItemText: (maxItemCount) => {
      return `Only ${maxItemCount} values can be added`;
    },
    valueComparer: (value1, value2) => {
      return value1 === value2;
    },
    classNames: {
      containerOuter: 'choices',
      containerInner: 'choices__inner',
      input: 'choices__input',
      inputCloned: 'choices__input--cloned',
      list: 'choices__list',
      listItems: 'choices__list--multiple',
      listSingle: 'choices__list--single',
      listDropdown: 'choices__list--dropdown',
      item: 'choices__item',
      itemSelectable: 'choices__item--selectable',
      itemDisabled: 'choices__item--disabled',
      itemChoice: 'choices__item--choice',
      placeholder: 'choices__placeholder',
      group: 'choices__group',
      groupHeading: 'choices__heading',
      button: 'choices__button',
      activeState: 'is-active',
      focusState: 'is-focused',
      openState: 'is-open',
      disabledState: 'is-disabled',
      highlightedState: 'is-highlighted',
      selectedState: 'is-selected',
      flippedState: 'is-flipped',
      loadingState: 'is-loading',
      noResults: 'has-no-results',
      noChoices: 'has-no-choices'
    },
    // Choices uses the great Fuse library for searching. You
    // can find more options here: https://github.com/krisk/Fusepion
    fuseOptions: {
      include: 'score'
    },
    callbackOnInit: null,
    callbackOnCreateTemplates: null
  });

  regionsSelect.addEventListener(
    'change',
    function(event) {
      var region_val = regions.getValue(true)
      states.setChoiceByValue(rigion_hash[region_val[region_val.length-1]]);
      if (statesSelect.dataset.states !== '[]') {
        statesSelect.dataset.states = (statesSelect.dataset.states + rigion_hash[region_val[region_val.length-1]]).split(',').filter((item, i, ar) => ar.indexOf(item) === i).join(',')
      } else {
        statesSelect.dataset.states = rigion_hash[region_val[region_val.length-1]]
      }
      var selectEvent = new CustomEvent(`states-change`, {});
      statesSelect.dispatchEvent(selectEvent);
    },
    false,
  );
}

const createSportSelect = function () {
    var sportSelect = document.getElementById('with_sport')
  
    // Passing options (with default options)
    const sport = new Choices(sportSelect, {
        silent: false,
        items: [],
        choices: [],
        renderChoiceLimit: -1,
        maxItemCount: -1,
        addItems: true,
        addItemFilter: null,
        removeItems: true,
        removeItemButton: true,
        editItems: false,
        duplicateItemsAllowed: true,
        delimiter: ',',
        paste: true,
        searchEnabled: true,
        searchChoices: true,
        searchFloor: 1,
        searchResultLimit: 7,
        searchFields: ['label', 'value'],
        position: 'auto',
        resetScrollPosition: true,
        shouldSort: true,
        shouldSortItems: false,
        sorter: () => {},
        placeholder: true,
        placeholderValue: "",
        searchPlaceholderValue: "",
        prependValue: null,
        appendValue: null,
        renderSelectedChoices: 'auto',
        loadingText: 'Loading...',
        noResultsText: 'No results found',
        noChoicesText: 'No choices to choose from',
        itemSelectText: '+',
        addItemText: (value) => {
        return `Press Enter to add <b>"${value}"</b>`;
        },
        maxItemText: (maxItemCount) => {
        return `Only ${maxItemCount} values can be added`;
        },
        valueComparer: (value1, value2) => {
        return value1 === value2;
        },
        classNames: {
          containerOuter: 'choices',
          containerInner: 'choices__inner',
          input: 'choices__input',
          inputCloned: 'choices__input--cloned',
          list: 'choices__list',
          listItems: 'choices__list--multiple',
          listSingle: 'choices__list--single',
          listDropdown: 'choices__list--dropdown',
          item: 'choices__item',
          itemSelectable: 'choices__item--selectable',
          itemDisabled: 'choices__item--disabled',
          itemChoice: 'choices__item--choice',
          placeholder: 'choices__placeholder',
          group: 'choices__group',
          groupHeading: 'choices__heading',
          button: 'choices__button',
          activeState: 'is-active',
          focusState: 'is-focused',
          openState: 'is-open',
          disabledState: 'is-disabled',
          highlightedState: 'is-highlighted',
          selectedState: 'is-selected',
          flippedState: 'is-flipped',
          loadingState: 'is-loading',
          noResults: 'has-no-results',
          noChoices: 'has-no-choices'
        },
        // Choices uses the great Fuse library for searching. You
        // can find more options here: https://github.com/krisk/Fusepion
        fuseOptions: {
        include: 'score'
        },
        callbackOnInit: null,
        callbackOnCreateTemplates: null
    });
}

const createTeamSelect = function () {
  var teamSelect = document.getElementById('jsTeam')
  // Passing options (with default options)

  const team = new Choices(teamSelect, {
    shouldSort: false
  });
  teamSelect.addEventListener(
    'change',
    function(event) {
      teamSelect.closest('form').submit();
    },
    false,
  );
}

document.addEventListener("turbo:before-cache", function() {
  ownership.destroy()

  console.log("turbo:before-cache")
  window.location.reload();
})


document.addEventListener("turbo:load", function () {
  console.log("turbo:load")
  if( document.getElementById('jsTeam') != null) {
    createTeamSelect()
  }
  createMajorsSelect()
  createDivisionsSelect()
  createClubSportsSelect()
  createConferencesSelect()
  createReligionsSelect()
  createStatesSelect()
  createSettingsSelect()
  createOwnershipSelect()
  createRegionsSelect()
  createSportSelect()
})


export { createSportSelect, createConferencesSelect };