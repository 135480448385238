import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.load()
  }

  load() {
    // fetch(this.data.get("url"))
    //   .then(response => response.text())
    //   .then(html => {
    //     this.element.innerHTML = html
    //   })

    let url = this.data.get("url")

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        console.log(data)
        this.element.innerHTML = data.html
      }
    })

  }
}