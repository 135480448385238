import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['appendix']

    connect() {
        "Stimulus appender connected"
        this.load()
    }

    load() {
        console.log("This is the script loading thing.")
        loadIframelyEmbedJs(); // Defined in head of layouts/application.html.erb
    }
}