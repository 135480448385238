import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['appendix']

    connect() {
        "Stimulus admin controller connected"
        this.setupAccordianToggle()
        this.setupEditor()
        this.setupViewer()
    }

    setupAccordianToggle() {
        console.log("Setting up accordian toggle")

        // $(".administrate-field-jsonb-accordion").each(function() {
        //     $(this).click(function() {
        //         $(this).toggleClass("administrate-field-jsonb-active").next().toggle();
        //     });
        // });

        let accordians = document.getElementsByClassName("administrate-field-jsonb-accordion")
        
        // .each(function() {
        //     this.click(function() {
        //         this.classList.toggle("administrate-field-jsonb-active").nextElementSibling.toggle();
        //     });
        // });

        Array.prototype.forEach.call(accordians, function(el) {
            // Do stuff here
            console.log(el.tagName);

            el.click(function() {
                el.classList.toggle("administrate-field-jsonb-active").nextElementSibling.toggle();
            });
        });
        
    }

    setupEditor() {
        // let editor, updatedJson;
        // $('.administrate-jsoneditor').each(function(index) {

        //     let $current = $(this).find("textarea");

        //     let options = {
        //     onChange: function () {
        //         try {
        //         updatedJson = editor.get();
        //         } catch (err) {
        //         console.log(err);
        //         }

        //         $current.val(JSON.stringify(updatedJson));
        //     },
        //     onError: function(err) {
        //         alert(err.toString());
        //     },
        //     navigationBar: false,
        //     search: false,
        //     enableSort: false,
        //     enableTransform: false,
        //     mode: 'tree',
        //     modes: ['text', 'tree'],
        //     };

        //     let editor = new JSONEditor(this, options);

        //     editor.set(JSON.parse($current.val()));
        // });

        let JSONEditor = require('jsoneditor');

        let editor, updatedJson;
        let editors = document.getElementsByClassName("administrate-jsoneditor")

        Array.prototype.forEach.call(editors, function(el) {
            let $current = el.getElementsByTagName("textarea")[0];

            let options = {
            onChange: function () {
                try {
                updatedJson = editor.get();
                } catch (err) {
                console.log(err);
                }

                $current.value = JSON.stringify(updatedJson);
            },
            onError: function(err) {
                alert(err.toString());
            },
            navigationBar: false,
            search: false,
            enableSort: false,
            enableTransform: false,
            mode: 'tree',
            modes: ['text', 'tree'],
            };

            let editor = new JSONEditor(el, options);

            editor.set(JSON.parse($current.value));
        });

    }

    setupViewer() {
        let viewer;
        let viewers = document.getElementsByClassName("administrate-jsoneditor-viewer");
        
        Array.prototype.forEach.call(viewers, function(el) {      
          let $current = el.getElementsByTagName("textarea")[0];
      
          let options = {
            navigationBar: false,
            search: false,
            enableSort: false,
            enableTransform: false,
            mode: 'view',
            modes: [],
          };
      
          let viewer = new JSONEditor(el, options);
      
          viewer.set(JSON.parse($current.value));
        });
    }

}