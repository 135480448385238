// filters_controller.js
import { Controller } from "stimulus";
import { createConferencesSelect } from "../src/choices_select"

export default class extends Controller {
  static targets = [
    "container",
    "count",
    "colleges",
    "loadMoreTrigger",
    "pagination",
    "satLow",
    "satHigh",
    "actLow",
    "actHigh",
    "gpaLow",
    "gpaHigh",
    "majorsSelect",
    "withSport",
    "divisionsSelect",
    "conferences",
    "club_sports",
    // "conferenceSelectWrapper",
    "rightSizeMin",
    "rightSizeMax",
    "ownership",
    "isReligious",
    "isNewProgram",
    "coaLow",
    "coaHigh",
    // "netPriceLow",
    // "netPriceHigh",
    "states",
    "searchQuery",
    "settings"
  ];

  initialize() {
    let options = {
      rootMargin: '500px',
    }

    this.intersectionObserver = new IntersectionObserver(colleges => this.processIntersectionColleges(colleges), options)
  }

  connect() {

    console.log("Filter is connecting")

    this.load()
    this.intersectionObserver.observe(this.loadMoreTriggerTarget)
  }

  disconnect() {

    console.log("Disconnecting")

    this.intersectionObserver.unobserve(this.loadMoreTriggerTarget)
  }

  processIntersectionColleges(colleges) {
    colleges.forEach(college => {
      if (college.isIntersecting) {
        this.loadMore()
      }
    })
  }

  load() {


    console.log('Load is called')

    let url = `${window.location.pathname}?${this.params}`

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {


        // if (this.conferenceSelectWrapperTarget) {
        //   this.conferenceSelectWrapperTarget.innerHTML = data.conferences
        // }

        // createConferencesSelect()

        this.collegesTarget.innerHTML = data.colleges
        this.countTarget.innerHTML = data.count
        this.paginationTarget.innerHTML = data.pagination
        this.is_enable(data)

      }
    })
  }

  is_enable(data) {
    if (data.enable_divisions == false) {
      var show_division = document.getElementById("jsDivision");
      show_division.classList.remove('hidden');

    }else{
      var show_division = document.getElementById("jsDivision");
      show_division.classList.add('hidden');
    }
  }

  loadMore() {
    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) {return}
    let url = `${next_page.href}&${this.params}`

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.collegesTarget.insertAdjacentHTML('beforeend', data.colleges)
        this.paginationTarget.innerHTML = data.pagination
      }
    })
  }

  filter() {
    this.load()
    this.searchQueryTarget.value = ""

    if (document.getElementById('query') != null) {
      document.getElementById('query').value=''
    }
  }

  setSport() {
    let formData = new FormData()
    formData.append("sport_id", this.withSportTarget.value);

    fetch(`${this.withSportTarget.dataset.sportUpdateUrl}`, {
      body: formData,
      method: 'PATCH',
      dataType: 'script',
      credentials: "include",
      headers: {
              "X-CSRF-Token": getMetaValue("csrf-token")
       },
    })
    .then(function(response) {
      Turbo.visit(location.toString());
    })

    function getMetaValue(name) {
      const element = document.head.querySelector(`meta[name="${name}"]`)
      return element.getAttribute("content")
    }

  }

  get params() {

    console.log(this.sportInDivision)

    return [
      this.sportInDivision,
      this.conferences,
      this.club_sports,
      this.satRange,
      this.actRange,
      this.gpaRange,
      this.majorsSelect,
      // this.withSport,
      // this.divisionsSelect,
      this.searchQuery,
      this.rightSize,
      this.ownership,
      this.isReligious,
      this.isNewProgram,
      this.coaRange,
      // this.netPriceRange,
      this.states,
      this.settings
    ].join("&");
  }

  get sportInDivision() {

    if (this.divisionsSelectTarget.dataset.divisions !== []) {
      if (this.divisionsSelectTarget.value !== "") {
        return `sport_in_division[sport]=${this.withSportTarget.value}&sport_in_division[division]=${this.divisionsSelectTarget.dataset.divisions}`
        }else {
        return `sport_in_division[sport]=${this.withSportTarget.value}&sport_in_division[division]=[]`
      }
    } else {
      if (this.divisionsSelectTarget.value !== "") {
        console.log("option 2")
        return `sport_in_division[sport]=${this.withSportTarget.value}&sport_in_division[division]=${this.divisionsSelectTarget.value}`
      } else {
        console.log("option 3")
        return `sport_in_division[sport]=${this.withSportTarget.value}&sport_in_division[division]=`
      }
    }
  }

  get satRange() {
    return `sat_range[low]=${this.satLowTarget.value}&sat_range[high]=${this.satHighTarget.value}&sat_in_range_or_na[low]=${this.satLowTarget.value}&sat_in_range_or_na[high]=${this.satHighTarget.value}`
  }

  get actRange() {
    return `act_range[low]=${this.actLowTarget.value}&act_range[high]=${this.actHighTarget.value}&act_in_range_or_na[low]=${this.actLowTarget.value}&act_in_range_or_na[high]=${this.actHighTarget.value}`
  }

  get gpaRange() {
    return `gpa_range[low]=${this.gpaLowTarget.value}&gpa_range[high]=${this.gpaHighTarget.value}&gpa_in_range_or_na[low]=${this.gpaLowTarget.value}&gpa_in_range_or_na[high]=${this.gpaHighTarget.value}`
  }

  get majorsSelect() {
    return `majors_select[]=${this.majorsSelectTarget.dataset.majors}`;
  }

  get rightSize() {
    return `right_size[min]=${this.rightSizeMinTarget.value}&right_size[max]=${this.rightSizeMaxTarget.value}`
  }

  get ownership() {
    return `ownership=${this.ownershipTarget.value}`;
  }
  
  get searchQuery() {
    return `query=${this.searchQueryTarget.value}`;
  }


  get isReligious() {
    if (this.isReligiousTarget.checked == true) {
      return `is_religious=true`;
    } else {
      return `is_religious=false`;
    }
    
  }

  get coaRange() {
    return `coa_range[low]=${this.coaLowTarget.value}&coa_range[high]=${this.coaHighTarget.value}`
  }

  // get netPriceRange() {
  //   return `net_price_range[low]=${this.netPriceLowTarget.value}&net_price_range[high]=${this.netPriceHighTarget.value}`
  // }

  get states() {
    // return `states[]=${this.statesTarget.value}`;

    if (this.statesTarget.dataset.states !== 'undefined') {
      return `states[]=${this.statesTarget.dataset.states}`;
    } else {
      if (this.statesTarget.value !== 'undefined') {
        return `states[]=${this.statesTarget.value}`;
      } else {
        return `states[]=`;
      }
    }
  }

  get conferences(){
    if (this.conferencesTarget.dataset.conferences == '[]') {
      return `conferences[]=`;
    } else {
      if (typeof this.conferencesTarget.dataset.conferences !== 'undefined') {
        return `conferences[]=${this.conferencesTarget.dataset.conferences}`;
      } else {
        return `conferences[]=`;
      }
    }
  }
  get club_sports(){
    if (this.club_sportsTarget.dataset.club_sports == '[]') {
      return `club_sports[]=`;
    } else {
      if (typeof this.club_sportsTarget.dataset.club_sports !== 'undefined') {
        return `club_sports[]=${this.club_sportsTarget.dataset.club_sports}`;
      } else {
        return `club_sports[]=`;
      }
    }
  }

  // get conferences() {

  //   if (this.conferencesTarget.dataset.conferences !== []) {
  //     if (this.conferencesTarget.value !== "") {
  //       return `conferences=${this.conferencesTarget.dataset.conferences}`
  //       }else {
  //       return `conferences=[]`
  //     }
  //   } else {
  //     if (this.conferencesTarget.value !== "") {
  //       console.log("option 2")
  //       return `conferences=${this.conferencesTarget.value}`
  //     } else {
  //       console.log("option 3")
  //       return `conferences=`
  //     }
  //   }
  // }

  get settings() {
    if (typeof this.settingsTarget.dataset.settings !== 'undefined') {
      return `settings[]=${this.settingsTarget.dataset.settings}`;
    } else {
      if (typeof this.settingsTarget.value !== 'undefined') {
        return `settings[]=${this.settingsTarget.value}`;
      } else {
        return `settings[]=`;
      }
    }
  }

  get isNewProgram() {
    if (this.isNewProgramTarget.checked == true) {
      return `is_new_program=true`;
    } else {
      return `is_new_program=false`;
    }

  }

}