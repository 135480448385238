export function loadVideoSourceSelect(videoId) {

    console.log("loadVideoSourceSelect called", videoId)

    var clipEditor = document.getElementById(`video_${videoId}`)
    var video = clipEditor.getElementsByClassName('video')[0];
    var video_title = document.getElementById('video_title');
    var video_source_id = document.getElementById('video_source_id');

    var changeVideoSource = function () {
        var sources = video.getElementsByTagName('source');
        var video_source_value = video_source_id.options[video_source_id.selectedIndex].getAttribute('value')
        console.log(video_source_value)
        var selected_url = video_source_id.options[video_source_id.selectedIndex].getAttribute('url');
        
        video.pause()

        sources[0].setAttribute('src', selected_url);
        sources[1].setAttribute('src', selected_url);
        sources[2].setAttribute('src', selected_url);

        document.getElementsByClassName('video_video_source_id')[0].value = video_source_value

        video.load();
        video.play();
    };

    var changeTitle = function () {
        var selected_title = video_source_id.options[video_source_id.selectedIndex].text + ' clip';
        video_title.setAttribute('value', selected_title)  
    }


    video_source_id.addEventListener('change', function() {
        changeVideoSource();
        changeTitle();
    });


};

document.addEventListener("turbo:load", () => {
    loadVideoSourceSelect();
});