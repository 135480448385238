import { Controller } from "stimulus"
import Sortable from "sortablejs"


export default class extends Controller {
  static targets = [
    "sort",
    "composite",
  ];


  connect() {
    this.sortable = Sortable.create(this.sortTarget, {
        handle: ".sort-handle",
        onEnd: this.end.bind(this)
    })
  }

  end(event) {    

      let id = event.item.dataset.id
      let data = new FormData()
      data.append("position", event.newIndex)

      Rails.ajax({
          url: `/playlist_videos/${id}/move`,
          type: 'PATCH',
          data: data,
          success: (response) => {
            console.log(response)
          },
      })
  }
}