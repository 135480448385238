import {Controller} from "stimulus"
import {Uppy} from '@uppy/core'
import {Dashboard} from '@uppy/dashboard'
import CreativeEngine from "@cesdk/engine";
// import * as url from "url";
// import {ActiveStorageUpload} from '@excid3/uppy-activestorage-upload'
// const ActiveStorageUpload = require('@excid3/uppy-activestorage-upload')

// require('@uppy/core/dist/style.css')
// require('@uppy/dashboard/dist/style.css')


export default class extends Controller {

    connect() {
        alert("hello")
        this.imgly_editor()

    }

    imgly_editor() {

        alert("testing")


        window.onload = async () => {
            const container = document.getElementById('cesdk');

            alert(container)

            if (!container) return;


            const config = {
                theme: 'light',
                baseURL: 'https://cdn.img.ly/packages/imgly/cesdk-js/1.10.1/assets',
                initialSceneMode: 'Video',
                // initialSceneString: 'UBQ1ewoiZm9ybW…', // A scene string
                // initialImageURL: "https://images.unsplash.com/photo-1575936123452-b67c3203c357?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8&w=1000&q=80", // A URL pointing to an image file
                ui: {
                    elements: {
                        view: 'default',
                        panels: {
                            settings: true
                        },
                        navigation: {
                            position: 'top',
                            action: {
                                save: true,
                                load: true,
                                download: true,
                                export: true
                            }
                        }
                    }
                },
                callbacks: {
                    onUpload: 'local',
                    onSave: (scene) => {
                        const element = document.createElement('a');;
                        element.setAttribute(
                            'href',
                            `cesdk-${url}.scene`
                        );
                        element.setAttribute(
                            'download',
                            `cesdk-${url}.scene`
                        );


                        element.style.display = 'none';
                        document.body.appendChild(element);


                        element.click();


                        document.body.removeChild(element);
                    },
                    onLoad: 'upload',
                    onDownload: 'download',
                    onExport: 'download'
                }
            };


            const cesdk = await CreativeEditorSDK.init('#cesdk', config);
            cesdk.addDefaultAssetSources();
            cesdk.addDemoAssetSources();


            window.cyGlobals = {
                cesdk
            };

        }
        function paginateAssetResult(
            array,
            { page, perPage } = {
                page: 1,
                perPage: 10
            }
        ) {
            const pageOffset = (page ?? 0) * perPage;
            const assetsInCurrentPage = array.slice(pageOffset, pageOffset + perPage);
            const total = array.length;
            const currentPage = page;

            const totalFetched = page * perPage + assetsInCurrentPage.length;
            const nextPage = totalFetched < total ? currentPage + 1 : undefined;

            return {
                assets: assetsInCurrentPage,

                total,
                currentPage,
                nextPage
            };
        }

        function applyQuerySearch(assets, querySearch) {
            return querySearch
                ? assets.filter((asset) => {
                    return (asset.label || '')
                        .toLowerCase()
                        .includes(querySearch.toLowerCase());
                })
                : assets;
        }


    }

}
