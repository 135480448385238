import { Controller } from 'stimulus'
import { loadHighlighter } from '../src/video_highlighter';
import { loadTrimmer } from '../src/video_trimmer';

export default class extends Controller {
  static targets = ['display']

  render(evt) {
    console.log(evt)
    console.log("Renderer#render js controller this is what is called.")

    this.displayTarget.innerHTML = evt.detail[0].body.innerHTML

    loadTrimmer();
    loadHighlighter();
  }
}