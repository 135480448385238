import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "sidebar" ]

  show() {
    this.sidebarTargets.forEach( target => {
        target.classList.remove("hidden")
    })
  }

  hide() {
    this.sidebarTargets.forEach( target => {
      target.classList.add("hidden")
    })
  }
}