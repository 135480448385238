import { Controller } from "stimulus"

export default class extends Controller {

    static targets = ['changeTeam']

    connect() {
        // this.load(this.idTarget.id)
        // alert("Connected enabler!")
    }

    toggleButtonAbility() {

        var checkboxes = document.querySelectorAll("input[type='checkbox']");

        var aBoxIsChecked = false
        
        checkboxes.forEach((box) => {
            if (box.checked) {
                aBoxIsChecked = true
                return
            }
        });

        console.log(aBoxIsChecked)

        if (aBoxIsChecked) {
            this.changeTeamTarget.disabled = false
        } else {
            this.changeTeamTarget.disabled = true
        }
    }

    checkAll() {
        var checkAllBox = document.getElementById("checkAll")
        var checkboxes = document.querySelectorAll("input[type='checkbox']");
        checkboxes.forEach((box) => {
            box.checked = checkAllBox.checked
        })

        this.toggleButtonAbility()
    }

    checkAllStaff() {
        var checkAllBox = document.getElementById("checkAllStaff")
        var checkboxes = document.querySelectorAll(".jsStaffTable input[type='checkbox']");
        checkboxes.forEach((box) => {
            box.checked = checkAllBox.checked
        })

        this.toggleButtonAbility()
    }

    checkAllPlayer() {
        var checkAllBox = document.getElementById("checkAllPlayer")
        var checkboxes = document.querySelectorAll(".jsPlayerTable input[type='checkbox']");
        checkboxes.forEach((box) => {
            box.checked = checkAllBox.checked
        })

        this.toggleButtonAbility()
    }

}
