export function loadHighlighter(videoId) {

    console.log("loadHighlighter called", videoId)
    var clipEditor = document.getElementById(`video_${videoId}`)

    var video = clipEditor.getElementsByClassName('video')[0]
    var container = clipEditor.getElementsByClassName("drag-video-container")[0];
    var dragItem = clipEditor.getElementsByClassName("drag-circle")[0].getElementsByTagName('img')[0];
    var saveHighlight = clipEditor.getElementsByClassName("save-highlight")[0];

    var active = false;
    var currentX;
    var currentY;
    var initialX;
    var initialY;
    var xOffset = 0;
    var yOffset = 0;

    var x_coord_percentage;
    var y_coord_percentage;

    container.addEventListener("touchstart", dragStart, false);
    container.addEventListener("touchend", dragEnd, false);
    container.addEventListener("touchmove", drag, false);

    container.addEventListener("mousedown", dragStart, false);
    container.addEventListener("mouseup", dragEnd, false);
    container.addEventListener("mousemove", drag, false);

    function dragStart(e) {

        // if (e.type === "touchstart") {
        //     initialX = e.touches[0].clientX - xOffset;
        //     initialY = e.touches[0].clientY - yOffset;
        // } else {
        //     initialX = e.clientX - xOffset;
        //     initialY = e.clientY - yOffset;
        // }

        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX;
            initialY = e.touches[0].clientY;
        } else {
            initialX = e.clientX;
            initialY = e.clientY;
        }

        if (e.target === dragItem) {
            active = true;
        }
    }

    function dragEnd(e) {

        if (e.target.tagName == "IMG") {
            initialX = currentX;
            initialY = currentY;
    
            var wrapperBox = clipEditor.getElementsByClassName('wrapper')[0]
            var videoBox = clipEditor.getElementsByClassName('video')[0]

            console.log(clipEditor.getElementsByClassName('video')[0])

            var x_offset_from_center = currentX + dragItem.offsetLeft - videoBox.offsetLeft - (videoBox.offsetWidth / 2) + (dragItem.offsetWidth / 2);
            var y_offset_from_center = currentY + dragItem.offsetTop - videoBox.offsetTop - (videoBox.offsetHeight / 2) + (dragItem.offsetHeight / 2);
            
            console.log("videoBox.offsetWidth", videoBox.offsetWidth)
            console.log("videoBox.offsetHeight", videoBox.offsetHeight)
            console.log("dragItem.offsetWidth", dragItem.offsetWidth)
            console.log("dragItem.offsetHeight", dragItem.offsetHeight)

            console.log("Center Offset Percentages:", x_offset_from_center/videoBox.offsetWidth, y_offset_from_center/videoBox.offsetHeight )
    
            var x_coord_absolute = currentX + dragItem.offsetLeft - videoBox.offsetLeft
            var y_coord_absolute = currentY + dragItem.offsetTop - videoBox.offsetTop
    
            x_coord_percentage = x_coord_absolute/videoBox.offsetWidth
            y_coord_percentage = y_coord_absolute/videoBox.offsetHeight
    
            var startOffset = clipEditor.getElementsByClassName(`video_start_offset`)[0]
            // var endOffset = clipEditor.getElementsByClassName(`video_end_offset`)[0],
    
            console.log("video.currentTime", video.currentTime)
            console.log("startOffset.value", parseFloat(startOffset.value))
            console.log("video.currentTime - startOffset", video.currentTime - parseFloat(startOffset.value))

            if (video.currentTime > startOffset.value) {
                clipEditor.getElementsByClassName('video_id_start_offset')[0].value = video.currentTime
            } else {
                clipEditor.getElementsByClassName('video_id_start_offset')[0].value = startOffset.value
            }
            
            clipEditor.getElementsByClassName('video_id_x_offset')[0].value = x_offset_from_center/videoBox.offsetWidth
            clipEditor.getElementsByClassName('video_id_y_offset')[0].value = ((y_offset_from_center/videoBox.offsetHeight))
    
        }

        active = false;
    }

    function drag(e) {

        if (active) {    
        
            e.preventDefault();

            if (e.type === "touchmove") {
            currentX = e.touches[0].clientX - initialX;
            currentY = e.touches[0].clientY - initialY;
            } else {
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
            }

            xOffset = currentX;
            yOffset = currentY;

            console.log(currentX)
            console.log(currentY)


            setTranslate(currentX, currentY, dragItem);
        }
    }

    function setTranslate(xPos, yPos, el) {

        console.log("setTranslate is called")
        console.log(el)
        console.log(xPos)
        console.log(yPos)

        el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
    }

    function sendIdentifierData(id_offset, x_offset, y_offset) {

        let formData = new FormData()

        formData.append("id_start_offset", id_offset);
        formData.append("id_x_offset", x_offset);
        formData.append("id_y_offset", y_offset);

        fetch(`/videos/${videoId}`, {
            body: formData,
            method: 'PATCH',
            dataType: 'script',
            credentials: "include",
            headers: {
                "X-CSRF-Token": getMetaValue("csrf-token")
            },
        })
        .then(function(response) {
            console.log(response)
            Turbo.visit(response.url);
        })

        function getMetaValue(name) {
            const element = document.head.querySelector(`meta[name="${name}"]`)
            return element.getAttribute("content")
        }
    }

    saveHighlight.addEventListener('click', function(event){
        console.log("sending data:", video.currentTime, x_coord_percentage, y_coord_percentage)
        sendIdentifierData(video.currentTime, x_coord_percentage, y_coord_percentage)
    });

};

document.addEventListener("turbo:load", () => {
    loadHighlighter();
});