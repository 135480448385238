addEventListener("direct-upload:initialize", event => {

  console.log('Initializing Direct Uplaods')

  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div class="w-full">
      <div id="direct-upload-${id}" data-startTimestamp="" class="direct-upload direct-upload--pending relative pt-1">
        <div class="flex mb-2 items-center justify-between">
          <div>
            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-primary-600 bg-primary-thin">
              Uploading: <span class="direct-upload__filename text-xs font-semibold inline-block lowercase">${file.name}</span>
            </span>
          </div>
          <div class="text-right">
            <span id="direct-upload--digital-progress-${id}" class="text-xs font-semibold inline-block text-primary-500">
              0%
            </span>
            <span id="direct-upload--remaining-${id}" class="text-xs font-semibold inline-block text-primary-500">
            </span>
          </div>
        </div>
        <div class="overflow-hidden mb-4 text-xs flex rounded-full bg-primary-thin">
          <div id="direct-upload-progress-${id}" style="width:0%" class="p-1 direct-upload__progress relative shadow-none flex flex-col text-start whitespace-nowrap text-white justify-center bg-primary-500">
          </div>
        </div>
      </div>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {

  console.log("direct-upload:start")

  console.log(event)

  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
  element.dataset.startTimestamp = event.timeStamp
})

addEventListener("direct-upload:progress", event => {

  const element = document.getElementById(`direct-upload-${id}`)

  console.log(event)
  console.log("direct-upload:progress")

  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)

  const remainingElement = document.getElementById(`direct-upload--remaining-${id}`)
  const digitalProgressElement = document.getElementById(`direct-upload--digital-progress-${id}`)
  progressElement.style.width = `${progress}%`
  digitalProgressElement.innerHTML = `<span>${Math.round(progress * 10) / 10}%</span>`

  const uploadObject = document.getElementById(`direct-upload-${id}`)
  const uploadTimestamp = uploadObject.dataset.startTimestamp

  const progressTimestamp = event.timeStamp

  const timeElapsed = progressTimestamp - uploadTimestamp

  const estimatedTotalTime = timeElapsed/(progress/100)
  console.log("EstimatedTotal", estimatedTotalTime)
  console.log("EstimatedRemainingTime", estimatedTotalTime - timeElapsed)
  const remainingTime = estimatedTotalTime - timeElapsed

  console.log(uploadTimestamp, progressTimestamp, timeElapsed, progress, remainingTime)

  digitalProgressElement.innerHTML = `<span class="p-4">${Math.round(progress * 10) / 10}%</span>`
  remainingElement.innerHTML = `<span>${millisecondsToStr(remainingTime)} remaining</span>`

})

function millisecondsToStr (milliseconds) {
  // TIP: to find current time in milliseconds, use:
  // var  current_time_milliseconds = new Date().getTime();

  function numberEnding (number) {
      return (number > 1) ? 's' : '';
  }

  var temp = Math.floor(milliseconds / 1000);
  var years = Math.floor(temp / 31536000);
  if (years) {
      return years + ' year' + numberEnding(years);
  }
  //TODO: Months! Maybe weeks? 
  var days = Math.floor((temp %= 31536000) / 86400);
  if (days) {
      return days + ' day' + numberEnding(days);
  }
  var hours = Math.floor((temp %= 86400) / 3600);
  if (hours) {
      return hours + ' hour' + numberEnding(hours);
  }
  var minutes = Math.floor((temp %= 3600) / 60);
  if (minutes) {
      return minutes + ' minute' + numberEnding(minutes);
  }
  var seconds = temp % 60;
  if (seconds) {
      return seconds + ' second' + numberEnding(seconds);
  }
  return 'less than a second'; //'just now' //or other string you like;
}

addEventListener("direct-upload:error", event => {

  console.log("direct-upload:error")

  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {

  console.log("direct-upload:end")

  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})
